/* style for chart */

.rv-xy-plot__axis__line {
  stroke: transparent !important;
}

.rv-xy-plot__axis__tick__text {
  fill: #b8b8c3 !important;
}

// Можно сденлать общие стили такого рода в корне
.ma {
  margin: auto !important;
}
