@import 'fonts/style.css';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    min-width: 375px;
    font-family: 'Graphik LC TT', sans-serif;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1920px) {
    body {
        overflow-y: overlay;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}
#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1025px) {
    .MuiContainer-maxWidthLg {
        max-width: 1480px !important;
    }
}
