/* regular 500 */
@font-face {
  font-family: 'Graphik LC TT';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Graphik LC TT'), url('Graphik-LC-TT.ttf') format('truetype');
}

/* light 400 */
@font-face {
  font-family: 'Graphik-Light';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Graphik-Light'), url('GraphikLCG-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik LC TT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Graphik LC TT'), url('GraphikLCG-Light.ttf') format('truetype');
}

/* bold */
@font-face {
  font-family: 'Graphik-Bold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Graphik-Bold'), url('GraphikLCG-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NormsPro Lite TT';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('NormsPro Lite TT'), url('TTNormsPro-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'NormsPro Regular TT';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('TTNormsPro Regular'), url('TTNormsPro-Regular.ttf') format('truetype');
}
