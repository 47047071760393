.LProgressBar {
  $self: &;

  &__bar {
    height: 12px;
    background: #fff;
    border: 1px solid #ebedef;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 9px;
  }

  &__fill {
    height: 100%;
    border-radius: 16px;
    background-color: #6bd689;
  }

  &__text {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.1px;
    color: #b8b8c3;
  }

  &__bottom {
    font-weight: 400;
    margin-top: 8px;
  }
}
